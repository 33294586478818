<template>
  <v-container fluid class="px-6" >

    <v-card elevation="2" >

      <v-card-title class="px-6 card-header white--text" >
        <v-icon class="mr-3 white--text">mdi-store-24-hour</v-icon> <h3>Station Registration Form</h3>
      </v-card-title>
      
      <v-form >
        <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-16' : 'px-1'" >
          <v-row style="height: calc(100vh - 280px);overflow-y:auto;">
            <v-col cols="12" md="6">
              <v-subheader class="pa-1 black--text">
                Station ID<span class="text-bold red--text mx-1">*</span>:
              </v-subheader>
              <v-text-field
                v-model="stationData.stationId"
                required
                outlined 
                dense hide-details
                autocomplete="off"
                :rules="[v => !!v || 'Station ID is required']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="pa-1 black--text">
                Location <span class="text-bold red--text mx-1">*</span>:
              </v-subheader>
              <v-text-field
                v-model="stationData.location"
                hint="Station location name"
                required
                outlined
                dense hide-details
                autocomplete="off"
                :rules="[v => !!v || 'Station location name is required']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="pa-1 black--text">
                Latitude <span class="text-bold red--text mx-1">*</span>:
              </v-subheader>
              <v-text-field
                v-model="stationData.latitude"
                required
                outlined
                dense hide-details
                autocomplete="off"
                :rules="[v => !!v || 'Station latitude is required']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="pa-1 black--text">
                Longitude <span class="text-bold red--text mx-1">*</span>:
              </v-subheader>
              <v-text-field
                v-model="stationData.longitude"
                required
                outlined
                dense hide-details 
                autocomplete="off"
                :rules="[v => !!v || 'Station longitude is required']"
              ></v-text-field>
            </v-col>
           
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">State :</v-subheader>
              <v-text-field
                v-model="stationData.stateName"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Telecommunication Type :</v-subheader>
              <v-text-field
                v-model="stationData.telecommunicationType"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Land Structure :</v-subheader>
              <v-text-field
                v-model="stationData.landStructure"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">Soil Type :</v-subheader>
              <v-text-field
                v-model="stationData.soilType"
                required
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">PIC Name :</v-subheader>
              <v-text-field
                v-model="stationData.picName"
                required
                outlined
                dense
                autocomplete="off"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">PIC Contact Number :</v-subheader>
              <v-text-field
                v-model="stationData.picPhone"
                required
                outlined
                dense
                autocomplete="off"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">PIC Email :</v-subheader>
              <v-text-field
                v-model="stationData.picEmail"
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0 pt-0">
              <v-subheader class="pa-1 black--text">
                Frequency of Data<span class="text-bold red--text mx-1">*</span>:
              </v-subheader
              >
              <v-select
                :items="listTime"
                :item-text="'name'"
                :item-value="'value'"
                v-model="stationData.timeAlertTransmission"
                required
                outlined
                dense
                :rules="[v => !!v || 'Time alert is required']"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>

            <v-col cols="12" md="12">
              <v-card outlined >
                <v-card-title class="black--text">Picture Station</v-card-title>

                <v-col>
                  <v-row class="pl-2 pr-4">
                    <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '6'" class="pb-0 pt-0">
                      <v-subheader class="pa-1 black--text">Station</v-subheader>
                      <v-file-input
                        @change="uploadPicture(uploadPic.file1, 'stationPicture')"
                        v-model="uploadPic.file1"
                        dense
                        outlined
                      ></v-file-input>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '6'" class="pb-0 pt-0">
                      <v-subheader class="pa-1 black--text">Deployment</v-subheader>
                      <v-file-input
                        @change="uploadPicture(uploadPic.file2, 'deploymentPicture')"
                        v-model="uploadPic.file2"
                        dense
                        outlined
                      ></v-file-input>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '6'" class="pb-0 pt-0">
                      <v-subheader class="pa-1 black--text">Upstream</v-subheader>
                      <v-file-input
                        @change="uploadPicture(uploadPic.file3, 'upstreamPicture')"
                        v-model="uploadPic.file3"
                        dense
                        outlined
                      ></v-file-input>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '6'" class="pb-0 pt-0">
                      <v-subheader class="pa-1 black--text">Downstream</v-subheader>
                      <v-file-input
                        @change="uploadPicture(uploadPic.file4, 'downStreamPicture')"
                        v-model="uploadPic.file4"
                        dense
                        outlined
                      ></v-file-input>
                    </v-col>
                    
                  </v-row>
                </v-col>
              </v-card>
            </v-col>

            <!-- <v-col cols="12" md="12" class="pb-2 pt-0">
              <v-btn class="mr-4 primary" @click="postStationData">
                Submit
              </v-btn>
            </v-col> -->
          </v-row>

          <v-divider></v-divider>

          <v-row class="pb-2 pt-4" justify="center" >
              <v-btn class="mr-4 button white--text" @click="postStationData"> SUBMIT </v-btn>
          </v-row>

        </v-container>
      </v-form>

    </v-card>

    <!-- Popups/Dialogs Success Param Added-->
    <div class="text-center">
        <v-dialog
        v-model="dialogSuccessStationAdded"
        width="500"
        >
        <v-card>
            <v-card-title class="text-h5 white--text button">
              <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Form has been submitted
            </v-card-title>

            <v-card-text class="pa-5 d-flex justify-center">
                <h3>Station has been added</h3> 
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              text
              @click="dialogSuccessStationAdded = false;reloadPage()"
              >
                  OK
              </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>

    <!-- Popups/Dialogs Error Add Station-->
    <div class="text-center">
        <v-dialog
        v-model="dialogErrorAddStation"
        width="500"
        >
        <v-card>
            <v-card-title class="text-h5 white--text error">
                <v-icon class="white--text mr-2">mdi-text-box-remove</v-icon>Form failed to submit
            </v-card-title>

            <v-card-text class="pa-5 d-flex justify-center">
                {{errorText}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            text
            @click="dialogErrorAddStation = false"
            >
              Close
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>


  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({

    dialogSuccessStationAdded: false,
    dialogErrorAddStation: false,
    errorText: "",
    
    minMaxItems: [],
    arrayMinMax: [],
    arrayMin: [],
    arrayMax: [],
    paramArray: [],
    listTime: [
      {name: "15 Minutes", value: "00:15"},
      {name: "30 Minutes", value: "00:30"},
      {name: "1 Hour", value: "00:00"},
    ],
    uploadPic: {
      file1: null,
      file2: null,
      file3: null,
      file4: null
    },
    stationData: {
      idCompany: "1",
      stationStatus: "1",
      alertStatus: "1",
      stationId: "",
      location: "",
      longitude: "",
      latitude: "",
      stateName: "",
      telecommunicationType: "",
      landStructure: "",
      soilType: "",
      picName: "",
      picPhone: "",
      picEmail: "",
      timeAlertTransmission: "",
      stationPicture: "",
      deploymentPicture: "",
      upstreamPicture: "",
      downStreamPicture: "",
    },
    paramItems: {
      DO_CON_ACTIVE: "",
      "4_4_DDT_ACTIVE": "",
      AG_ACTIVE: "",
      AL_ACTIVE: "",
      ALDRIN_DIELDRIN_ACTIVE: "",
      AS_ACTIVE: "",
      B_ACTIVE: "",
      BA_ACTIVE: "",
      BHC_ACTIVE: "",
      BOD_ACTIVE: "",
      BR_ACTIVE: "",
      CA_ACTIVE: "",
      CCE_ACTIVE: "",
      CD_ACTIVE: "",
      CDOM_ACTIVE: "",
      CHLORDANE_ACTIVE: "",
      CHLOROPHYLL_A_ACTIVE: "",
      CL_ACTIVE: "",
      CL2_ACTIVE: "",
      CN_ACTIVE: "",
      CO2_ACTIVE: "",
      COD_ACTIVE: "",
      COLOUR_ACTIVE: "",
      CONDUCTIVITY_ACTIVE: "",
      CR_ACTIVE: "",
      CR_III_ACTIVE: "",
      CR_IV_ACTIVE: "",
      CU_ACTIVE: "",
      DEPTH_ACTIVE: "",
      DO_SAT_ACTIVE: "",
      DOC_ACTIVE: "",
      E_COLI_ACTIVE: "",
      EC_ACTIVE: "",
      ENDOSULFAN_ACTIVE: "",
      F_ACTIVE: "",
      FC_ACTIVE: "",
      FDOM_ACTIVE: "",
      FE_ACTIVE: "",
      FLOWRATE_ACTIVE: "",
      GROSS_ALPHA_ACTIVE: "",
      GROSS_BETA_ACTIVE: "",
      HARDNESS_ACTIVE: "",
      HEDONAL_2_4_D_ACTIVE: "",
      HEPTACHLOR_EPOXIDE_ACTIVE: "",
      HG_ACTIVE: "",
      K_ACTIVE: "",
      LINDANE_ACTIVE: "",
      MBAS_ACTIVE: "",
      MG_ACTIVE: "",
      MN_ACTIVE: "",
      NA_ACTIVE: "",
      NH3N_ACTIVE: "",
      NH4_ACTIVE: "",
      NI_ACTIVE: "",
      NO2_ACTIVE: "",
      NO3_ACTIVE: "",
      OG_EDIBLE_ACTIVE: "",
      OG_MINERAL_ACTIVE: "",
      P_ACTIVE: "",
      PARAQUAT_ACTIVE: "",
      PB_ACTIVE: "",
      PCB_ACTIVE: "",
      pH_ACTIVE: "",
      PHENOL_ACTIVE: "",
      PO4_3_ACTIVE: "",
      RA226_ACTIVE: "",
      S_ACTIVE: "",
      SALINITY_ACTIVE: "",
      SE_ACTIVE: "",
      SN_ACTIVE: "",
      SO4_ACTIVE: "",
      SOLAR_BATTERY_ACTIVE: "",
      SILVEX_2_4_5_TP_ACTIV: "",
      SR_90_ACTIVE: "",
      TC_ACTIVE: "",
      TDS_ACTIVE: "",
      TEMPERATURE_ACTIVE: "",
      TOC_ACTIVE: "",
      TRIOXONE_2_4_5_T_ACTIVE: "",
      TSS_ACTIVE: "",
      TURBIDITY_ACTIVE: "",
      U_ACTIVE: "",
      VELOCITY_ACTIVE: "",
      ZN_ACTIVE: "",
      TRYPHPTOPHAN_ACTIVE: ""
    },

    paramQAQC: {},
    paramExceed: {},

  }),

  methods: {

    postStationData() {

      if (this.stationData.stationId == "" || this.stationData.location == ""
      || this.stationData.longitude == "" || this.stationData.latitude == ""
      || this.stationData.timeAlertTransmission == "") {
        this.errorText = "Please make sure all required form is filled and try again."
          this.dialogErrorAddStation = true;
      }
      else{
          // Post Data of Station Details
          let object = this.paramArray.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }),
            {}
          );

          const payload = { ...this.stationData, ...object };

          let keyData = Object.keys(this.paramItems);
          for(let i in keyData){

            // For QAQC
            this.paramQAQC["STATION_ID"] = this.stationData.stationId;
            this.paramQAQC[keyData[i].split("_ACTIVE")[0]+"_MIN"] = null;
            this.paramQAQC[keyData[i].split("_ACTIVE")[0]+"_MAX"] = null;
            this.paramQAQC[keyData[i].split("_ACTIVE")[0]+"_OUTLIER_MIN"] = null;
            this.paramQAQC[keyData[i].split("_ACTIVE")[0]+"_OUTLIER_MAX"] = null;

            // For Exceedance
            this.paramExceed["STATION_ID"] = this.stationData.stationId;
            this.paramExceed[keyData[i].split("_ACTIVE")[0]+"_MIN"] = null;
            this.paramExceed[keyData[i].split("_ACTIVE")[0]+"_MAX"] = null;

          }
          
          let paramStringQAQC = JSON.stringify(this.paramQAQC);
          let paramStringExceed = JSON.stringify(this.paramExceed);

          console.log(payload);

          axios.all([
            axios.post(this.globalUrl+'bakaj/addstation', payload, {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }),
            axios.post(this.globalUrl+'bakaj/qaqc1', paramStringQAQC, {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            }),
            axios.put(this.globalUrl+'maint/exceedanceedit', paramStringExceed, {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            }),
          ])
          .then(axios.spread((data1, data2, data3) => {
            this.dialogSuccessStationAdded = true;
          }))
          .catch((error) => {
            console.log(error.response.data.message);
            this.errorText = error.response.data.message;
            this.dialogSuccessStationAdded = false;
            this.dialogErrorAddStation = true;
          });
      }

    },






    uploadPicture(data, fileKey) {

      let formData = new FormData();

      formData.append("files", data);

      axios.post(this.globalUrl+'bakaj/upload', formData, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
        // this.stationData = response.data.filepath
        this.stationData[fileKey] = response.data.filepath;
      })
      .catch((error) => {
        console.log(error);
      });

    },

  },

  computed: {},

  created() {
    for (let key in this.paramItems) {
      this.paramArray.push({ name: key, param: key.split("_ACTIVE")[0], value: null, min: null, max: null, outlierMin: null, outlierMax: null });
    }
  },

  mounted() {
    // this.getMinMax();
  },
};
</script>

<style lang="scss" scoped>
.v-subheader {
  height: 30px;
}
</style>